import { useState } from "react";
import { Box, IconButton, InputBase, Typography, Select, MenuItem, FormControl, useTheme, useMediaQuery } from "@mui/material";
import { Search, Message, DarkMode, LightMode, Notifications, Help, Menu, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";

const Navbar = () => {
    const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false); // Mobile menu toggle state
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const isNonMobileScreen = useMediaQuery("(min-width: 1000px)");   //Determine if screen size is below min-width
    
    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.dark;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const alt = theme.palette.background.alt;

    const fullName = (user && `${user.firstName} ${user.lastName}`) || "Logged Out"; // If user is not logged in, set name to "John Doe"
    //const fullName = "John Doe";
    
    //sx: is a prop that allows to apply css to the component while using the Material UI theme


    return(
    <FlexBetween padding="1rem 6%" backgroundColor={alt}>
        <FlexBetween gap="1.75rem">
            <Typography
                fontWeight="bold"
                fontSize="clamp(1rem, 2rem, 2.25rem)"   //Responsive font size, min, preferred, max
                color="primary"
                onClick={() => navigate("/home")}
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                        color: primaryLight,
                    }
                }}
            >
                Voices
            </Typography>
            {isNonMobileScreen && (
                <FlexBetween backgroundColor={neutralLight} borderRadius="9px" gap="3rem" padding="0.1rem 1.5rem">
                    <InputBase placeholder="Search"/>
                    <IconButton>
                        <Search/>
                    </IconButton>
                </FlexBetween>
            )}
        </FlexBetween>
        
        {isNonMobileScreen ? (
            <FlexBetween gap="2rem">
                <IconButton onClick={() => dispatch(setMode())}>
                    {theme.palette.mode === "dark" ? <DarkMode sx={{fontSize: "25px"}}/> : <LightMode sx={{color: dark, fontSize: "25px"}}/>}
                </IconButton>
                <Message sx={{fontSize: "25px"}}/>
                <Notifications sx={{fontSize: "25px"}}/>
                <Help sx={{fontSize: "25px"}}/>
                <FormControl variant="standard" value={fullName}>
                    <Select
                        value={fullName}
                        sx={{
                            backgroundColor: neutralLight,
                            width: "150px",
                            borderRadius: "0.25rem",
                            padding: "0.25rem 0.5rem",
                            "& ..MuiSvgIcon-root": {
                                pr: "0.25rem",
                                width: "3rem"
                            },
                            "& .MuiSelect-select:focus": {
                                backgroundColor: neutralLight,
                            }
                        }}
                        input={<InputBase/>}
                    >
                        <MenuItem value={fullName}>
                            <Typography>{fullName}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => dispatch(setLogout())}>
                            <Typography>Logout</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
            </FlexBetween>
        ) : (
            <IconButton
                onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
                <Menu/>
            </IconButton>
        )}

        {/*Mobile menu*/}
        {!isNonMobileScreen && isMobileMenuToggled && (
            <Box
                position="fixed"
                right="0"
                bottom="0"
                height="100%"
                zIndex="0"
                maxWidth="500px"
                minWidth="300px"
                backgroundColor={background}
            >
                <Box display="flex" justifyContent="flex-end" padding="1rem">
                    <IconButton
                    onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
                    >
                        <Close/>
                    </IconButton>
                </Box>

                {/* Menu Items */}
                <FlexBetween display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="3rem">
                <IconButton 
                    onClick={() => dispatch(setMode())}
                    sx={{ fontSize: "25px" }} 
                >
                    {theme.palette.mode === "dark" ? <DarkMode sx={{fontSize: "25px"}}/> : <LightMode sx={{color: dark, fontSize: "25px"}}/>}
                </IconButton>
                <Message sx={{fontSize: "25px"}}/>
                <Notifications sx={{fontSize: "25px"}}/>
                <Help sx={{fontSize: "25px"}}/>
                <FormControl variant="standard" value={fullName}>
                    <Select
                        value={fullName}
                        sx={{
                            backgroundColor: neutralLight,
                            width: "150px",
                            borderRadius: "0.25rem",
                            padding: "0.25rem 0.5rem",
                            "& ..MuiSvgIcon-root": {
                                pr: "0.25rem",
                                width: "3rem"
                            },
                            "& .MuiSelect-select:focus": {
                                backgroundColor: neutralLight,
                            }
                        }}
                        input={<InputBase/>}
                    >
                        <MenuItem value={fullName}>
                            <Typography>{fullName}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => dispatch(setLogout())}>
                            <Typography>Logout</Typography>
                        </MenuItem>
                    </Select>
                </FormControl>
            </FlexBetween>

            </Box>
        )}
        

    </FlexBetween>
    );
}

export default Navbar;