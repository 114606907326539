import {
    EditOutlined,
    DeleteOutlined,
    AttachFileOutlined,
    GifBoxOutlined,
    ImageOutlined,
    MicOutlined,
    MoreHorizOutlined,
} from '@mui/icons-material';
import { Box, Divider, Typography, InputBase, useTheme, Button, IconButton, useMediaQuery } from '@mui/material';
import Dropzone  from 'react-dropzone';
import FlexBetween from 'components/FlexBetween';
import UserAvatar from 'components/UserAvatar';
import WidgetWrapper from 'components/WidgetWrapper';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPosts } from "state";

const NewPostWidget = ({ avatarPath }) => {
    const dispatch = useDispatch();
    const [isImage, setIsImage] = useState(false);
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');
    const { palette} = useTheme();
    const { _id } = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const isNonMobileScreen = useMediaQuery('(min-width:1000px)');
    const mediumMain = palette.neutral.mediumMain;
    const medium = palette.neutral.medium;

    const handlePost = async () => {
        const formData = new FormData();
        formData.append('userId', _id);
        formData.append('description', description);
        if(image){
            console.log("image", image);
            formData.append('image', image);
            formData.append("imagePath", image.name);
        }

        const response = await fetch('https://voices-backend.onrender.com/posts', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        const posts = await response.json();
        dispatch(setPosts({posts}));
        setImage(null);
        setDescription('');
    };
        return(
            <WidgetWrapper>
                <FlexBetween gap="1.5rem">
                    <UserAvatar image={avatarPath} />
                    <InputBase
                        placeholder="What's happening?"
                        onChange={(event) => setDescription(event.target.value)}
                        value={description}
                        sx={{
                            width:"100%",
                            backgroundColor: palette.neutral.light,
                            borderRadius: "2rem",
                            padding: "1rem 2rem"
                        }}
                    />
            </FlexBetween>
            {isImage && (
                <Box
                    border={`1px solid ${medium}`}
                    borderRadius="5px"
                    mt="1rem"
                    p="1rem"
                >
                    <Dropzone
                        acceptedFiles=".jpg,.jpeg,.png"
                        multiple={false}
                        onDrop={(acceptedFiles) => {
                            setImage(acceptedFiles[0]);
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <FlexBetween>
                                <Box 
                                {...getRootProps()}
                                border={`2px dashed ${palette.primary.main}`}
                                p="1rem"
                                sex={{ "&:hover": { cursor: "pointer" } }}
                            >
                                <input {...getInputProps()} />
                                {!image ? (
                                    <p> Add image here</p>
                                ) : (
                                    <FlexBetween>
                                        <Typography>{image.name}</Typography>
                                        <EditOutlined/>
                                    </FlexBetween>
                                )}
                            </Box>
                            {image && (
                                <IconButton>
                                    <DeleteOutlined 
                                        onClick={() => setImage(null)}
                                        sx={{width:"15%"}}
                                    />
                                </IconButton>
                            )}
                            </FlexBetween>     
                            )}
                    </Dropzone>
                </Box>
            )}
            <Divider sx={{ margin: "1.25rem 0" }} />
            <FlexBetween>
                <FlexBetween gap="0.25rem" onClick={() => setIsImage(!isImage)}>
                    <ImageOutlined sx={{ color: mediumMain }} />
                    <Typography 
                        color={mediumMain}
                        sx={{ "&:hover": {cursor: "pointer", color:medium}}}>
                            Image
                    </Typography>
                </FlexBetween>
                {isNonMobileScreen ? (
                    <>
                        <FlexBetween gap="0.25rem">
                            <GifBoxOutlined sx={{ color: mediumMain }} />
                            <Typography
                                color={mediumMain}
                            >Clip</Typography>
                        </FlexBetween>
                        <FlexBetween gap="0.25rem">
                            <AttachFileOutlined sx={{ color: mediumMain }} />
                            <Typography
                                color={mediumMain}
                            >Attachment</Typography>
                        </FlexBetween>
                        <FlexBetween gap="0.25rem">
                            <MicOutlined sx={{ color: mediumMain }} />
                            <Typography
                                color={mediumMain}
                            >Audio</Typography>
                        </FlexBetween>
                        
                    </>
                ): (<FlexBetween gap="0.25rem">
                <MoreHorizOutlined sx={{ color: mediumMain }} />
                </FlexBetween>)}
                <Button
                    disabled={!description}
                    onClick={handlePost}
                    sx={{
                        color: palette.background.alt,
                        backgroundColor: palette.primary.main,
                        borderRadius: "3rem",
                    }}
                >
                    Post
                </Button>
            </FlexBetween>
        </WidgetWrapper>
    );
};


export default NewPostWidget;