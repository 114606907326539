import { ChatBubbleOutlineOutlined, FavoriteBorderOutlined, FavoriteOutlined, ShareOutlined } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import ProfileHeader from "components/ProfileHeader";
import WidgetWrapper from "components/WidgetWrapper";
import Comment from "components/Comment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import NewComment from "components/NewComment";

const PostWidget = ({ postId, postUserId, name, description, imagePath, avatarPath, likes = [], comments }) => {

    const [isComments, setIsComments] = useState(false);
    const [myComments, setMyComments] = useState(comments);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.token);
    const loggedInUserId = useSelector((state) => state.user._id);
    const userAvatar = useSelector((state) => state.user.avatarPath);
    const isLiked = Boolean(likes[loggedInUserId]);
    const likeCount = Object.keys(likes).length;

    const { palette } = useTheme();
    const main = palette.neutral.main;
    const primary = palette.primary.main;

    const patchLike = async () => {
        const response = await fetch(`https://voices-backend.onrender.com/posts/${postId}/like`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
                },
                body: JSON.stringify({userId: loggedInUserId})
            });
        
        const data = await response.json();
        dispatch(setPost({post: data}));
    }

    return (
        
        <WidgetWrapper m="2rem 0">
            <ProfileHeader userId={postUserId} name={name} avatarPath={avatarPath} />
            <Typography color={main} sx={{ mt: "1rem" }}>
                {description}
            </Typography>
            {imagePath && (
                <img
                    width="100%"
                    height="auto"
                    alt="post"
                    style={{ borderRadius: "0.75rem", marginTop: "0.75rem"}}
                    src={`https://voices-backend.onrender.com/assets/${imagePath}`}
                />
            )}
            <FlexBetween mt="0.25rem">
                <FlexBetween gap="1rem">
                    <FlexBetween gap="0.3rem">
                        <IconButton onClick={() => patchLike()}>
                            {isLiked ? (
                                <FavoriteOutlined sx={{ color: primary }} />
                            ) : (
                                <FavoriteBorderOutlined />
                            )}
                        </IconButton>
                        <Typography>
                            {likeCount}
                        </Typography>
                    </FlexBetween>
                    <FlexBetween gap="0.3rem">
                        <IconButton onClick={() => setIsComments(!isComments)}>
                            {isComments ? (
                                <ChatBubbleOutlineOutlined sx={{ color: primary }} />
                            ) : (
                                <ChatBubbleOutlineOutlined />
                            )}
                        </IconButton>
                        <Typography>
                            {comments?.length}
                        </Typography>
                    </FlexBetween>
                </FlexBetween>
                <IconButton>
                    <ShareOutlined />
                </IconButton>
            </FlexBetween>
            {isComments &&(
                <Box mt="0.5rem">
                    {comments?.map((comment, i) => (
                        <Comment key={`${name}-${i}`}
                            comment={comment}
                         />
                    ))}
                    <NewComment avatarPath={userAvatar} postId={postId}/>
                </Box>
                
                
            )}
        </WidgetWrapper>
    )
}

export default PostWidget;