import { createSlice } from '@reduxjs/toolkit';


//State is data that persists even after the page is refreshed
const initialState = {
    mode: "light",
    user: null,
    token: null,
    posts: [],
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setMode: (state) => {
            console.log("Setting mode");
            state.mode = state.mode === "light" ? "dark" : "light"; // toggle between light and dark mode
        },
        setLogin: (state, action) => {      //Action is an object with a payload property
            console.log("Setting login");
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
        },
        setFollowers: (state, action) => {
            if(state.user){
                state.user.followers = action.payload.followers;
            }else{
                console.log("No user to set followers");
            }
        },
        setFollowing: (state, action) => {
            if(state.user){
                state.user.following = action.payload.following;
            }else{
                console.log("No user to set following");
            }
        },
        setPosts: (state, action) => {
            state.posts = action.payload.posts;
        },
        setPost: (state, action) => {
            const updatedPosts = state.posts.map((post) => {
                if(post._id === action.payload.post._id){
                    return action.payload.post;
                }else{
                    return post;
                }
            });
            state.posts = updatedPosts;
        },
    }
});

export const { setMode, setLogin, setLogout, setFollowers, setFollowing, setPosts, setPost } = authSlice.actions;
export default authSlice.reducer;