import { InputBase, useTheme, Button, useMediaQuery } from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import UserAvatar from 'components/UserAvatar';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPosts } from "state";

const NewComment = ({ avatarPath, postId }) => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState('');
    const { palette} = useTheme();
    const { _id } = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);

    const handleComment = async () => {
        const response = await fetch(`https://voices-backend.onrender.com/posts/${postId}/comment`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId: _id,
                content: description,
            }),
        });

        const posts = await response.json();
        dispatch(setPosts({posts}));
        setDescription('');
    };

    return(
                <FlexBetween gap="1rem" pl={"0.3rem"} pt={"0.4rem"}>
                    <UserAvatar image={avatarPath} size={"40px"} />
                    <InputBase
                        placeholder="What's happening?"
                        onChange={(event) => setDescription(event.target.value)}
                        value={description}
                        sx={{
                            width:"100%",
                            backgroundColor: palette.neutral.light,
                            borderRadius: "2rem",
                            padding: "0.2rem 1rem"
                        }}
                    />
                    <Button
                    disabled={!description}
                    onClick={handleComment}
                    sx={{
                        color: palette.background.alt,
                        backgroundColor: palette.primary.main,
                        borderRadius: "3rem",
                    }}
                    >
                        Post
                    </Button>
                </FlexBetween>

    );
};

export default NewComment;