import { ManageAccountsOutlined, EditOutlined } from "@mui/icons-material";
import { Box, Typography, Divider, useTheme } from '@mui/material';
import UserAvatar from "components/UserAvatar";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';

const UserWidget = ({ userId, avatarPath }) => {
    const [user, setUser] = useState(null);
    const { palette } = useTheme();
    const navigate = useNavigate();
    const token = useSelector((state) => state.token);
    const dark = palette.neutral.dark;
    const medium = palette.neutral.medium;
    const main = palette.neutral.main;
    

    const getUser = async () => {
        const response = await fetch(`https://voices-backend.onrender.com/users/${userId}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${token}`,},
        });
        const data = await response.json();
        setUser(data);
    };

    useEffect(()=>{ //This gets called when the component is rendered for the first time
        getUser();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if(!user) return null;

    const {
        firstName,
        lastName,
        followers,
        following,
    } = user;

    const avatarPath2 = user.avatarPath;

    return (
        <WidgetWrapper>
            <FlexBetween
                gap="0.5rem"
                pb="1.1rem"
                onClick={() => navigate(`/profile/${userId}`)}
            >
                <FlexBetween gap="1rem">
                    <UserAvatar image={avatarPath2} />
                    <Box>
                        <Typography 
                            variant="h4" 
                            color={dark}
                            fontWeight="500"
                            sx={{ 
                                "&:hover": {
                                    color: palette.primary.light,
                                    cursor: "pointer",
                                }
                            }}  
                        >
                            {firstName} {lastName}
                        </Typography>
                        <Typography color={medium}>
                            {followers ? followers.length : "0"} followers
                        </Typography>
                        <Typography color={medium}>
                            {following ? following.length : "0"} following
                        </Typography>
                    </Box>
                </FlexBetween>
                <ManageAccountsOutlined sx={{ color: main }} />
                </FlexBetween>
                <Divider />
                <Box p="1rem 0">
                    <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
                        Social Profiles
                    </Typography>
                    <FlexBetween gap="1rem" mb="0.5rem">
                        <FlexBetween gap="1rem">
                            <TwitterIcon/>
                            <Box>
                                <Typography color={main} fontWeight="500">
                                    Twitter
                                </Typography>
                                <Typography color={medium}>
                                    Social Network
                                </Typography>
                            </Box>
                        </FlexBetween>
                        <EditOutlined sx={{ color: main }} />
                    </FlexBetween>

                    <FlexBetween gap="1rem">
                        <FlexBetween gap="1rem">
                            <GitHubIcon/>
                            <Box>
                                
                                <Typography color={main} fontWeight="500">
                                    Github
                                </Typography>
                                <Typography color={medium}>
                                    Code Repository
                                </Typography>
                            </Box>
                        </FlexBetween>
                        <EditOutlined sx={{ color: main }} />
                    </FlexBetween>
                </Box>

            
        </WidgetWrapper>
    )

};

export default UserWidget;
