import { ManageAccountsOutlined, EditOutlined } from "@mui/icons-material";
import { Box, Typography, Divider, useTheme } from '@mui/material';
import UserAvatar from "components/UserAvatar";
import WidgetWrapper from "components/WidgetWrapper";
import FlexBetween from "components/FlexBetween";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AdWidget = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const dark = palette.neutral.dark;
    const medium = palette.neutral.medium;
    const main = palette.neutral.main;

    const getAd = async () => {
        //Get ad from backend
    };

    return (
        
        <WidgetWrapper>
            <FlexBetween pb={"1rem"}>
                <Typography
                    variant="h5" 
                    color={dark}
                    fontWeight="500"
                    
                >
                    Sponsored
                </Typography>
                
            </FlexBetween>
            <a href="https://www.redbubble.com/people/philkony/" target="_blank" rel="noreferrer" >
            <img width={"100%"} height={"auto"} style={{ borderRadius:"10px"}} src="https://i.imgur.com/j4g5CRm.png" alt="ad" />
            </a>
            <FlexBetween>
                <Typography color={main}>PhilKony</Typography>
                <Typography color={medium}>https://www.redbubble.com/people/philkony/</Typography>
            </FlexBetween>
            <Typography color={medium} m="0.5rem 0">
                Elevate your style with our custom-made t-shirts, where your imagination becomes wearable art. Stand out from the crowd and express your unique personality with designs as exceptional as you are.
            </Typography>
        </WidgetWrapper>
        
    );

};

export default AdWidget;