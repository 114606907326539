import { useState } from 'react';
import { Box, Button, TextField, useMediaQuery, Typography, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {setLogin} from 'state';
import Dropzone from 'react-dropzone';
import FlexBetween from 'components/FlexBetween';


// Form validation schema
const registerSchema = yup.object().shape({
    firstName:  yup.string().required("First name is required"),
    lastName:   yup.string().required("Last name is required"),
    email:    yup.string().email("Invalid email").required("Email is required"),
    password:   yup.string().required("Password is required"),
    avatar:    yup.mixed().required("Please upload a profile picture"),
});

const loginSchema = yup.object().shape({
    email:    yup.string().email("Invalid email").required("Email is required"),
    password:   yup.string().required("Password is required"),
});

const initialValuesRegister = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    avatar: "",
};

const initialValuesLogin = {
    email: "",
    password: "",
};

const Form = () => {
    const [pageType, setPageType] = useState("login");
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobileScreen = useMediaQuery("(min-width: 600px)");   //Determine if screen size is below min-width
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";

    const register = async (values, onSubmitProps) => {
        //allows to send form info with image
        const formData = new FormData();
        for (let value in values){
            formData.append(value, values[value]);
        }
        formData.append("avatarPath", values.avatar.name);
        const savedUserResponse = await fetch(
            "https://voices-backend.onrender.com/auth/register",
            {
                method: "POST",
                body: formData,
            }
        );
        const savedUser = await savedUserResponse.json();
        onSubmitProps.resetForm();

        if(savedUser){
            setPageType("login");
        }
    };

    const login = async (values, onSubmitProps) => {
        const loggedInResponse = await fetch(
            "https://voices-backend.onrender.com/auth/login",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
            }
        );

        const loggedIn = await loggedInResponse.json();
        onSubmitProps.resetForm();
        console.log(loggedIn);
        if (loggedIn) {
            dispatch(
                setLogin({
                user: loggedIn.user,
                token: loggedIn.token,
                })
            );
            
        }
        navigate("/home");
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        if(isLogin){
            await login(values, onSubmitProps);
        }
        else if(isRegister){
            await register(values, onSubmitProps);
        }
    };


    return (
        <Formik
        onSubmit={handleFormSubmit}
        initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
        validationSchema={isLogin ? loginSchema : registerSchema}>
            {({
                values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm
            }) =>(
                <form onSubmit={handleSubmit}>
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{"& > div" : {gridColumn: isNonMobileScreen ? undefined : "span 4"}}}>
                        {isRegister && (
                            <>
                                <TextField 
                                    label="First name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName}
                                    name="firstName"
                                    error={Boolean(touched.firstName) && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <TextField 
                                    label="Last name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName}
                                    name="lastName"
                                    error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <Box
                                    gridColumn="span 4"
                                    border={`1px solider ${palette.neutral.medium}`}
                                    borderRadius="5px"
                                    p="1rem"
                                >
                                    <Dropzone
                                        acceptedFiles=".jpg,.jpeg,.png"
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            setFieldValue("avatar", acceptedFiles[0]);
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <Box 
                                                {...getRootProps()}
                                                border={`2px dashed ${palette.primary.main}`}
                                                p="1rem"
                                                sex={{ "&:hover": { cursor: "pointer" } }}
                                            >
                                                <input {...getInputProps()} />
                                                {!values.avatar ? (
                                                    <p> Add avatar here</p>
                                                ) : (
                                                    <FlexBetween>
                                                        <Typography>{values.avatar.name}</Typography>
                                                        <EditOutlinedIcon/>
                                                    </FlexBetween>
                                                )}
                                            </Box>
                                         )}
                                    </Dropzone>
                                </Box>
                            </>
                        )}

                        {/* Login and Register */}
                        
                        <TextField 
                            label="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            error={Boolean(touched.email) && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            sx={{gridColumn: "span 4"}}
                        />
                        <TextField 
                            label="Password"
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password}
                            name="password"
                            error={Boolean(touched.password) && Boolean(errors.password)}
                            helperText={touched.password && errors.password}
                            sx={{gridColumn: "span 4"}}
                        />
                    </Box>
                    <Box>
                        <Button
                            fullWidth
                            type="submit"
                            sx={{
                                m: "2rem 0",
                                p: "1rem",
                                backgroundColor: palette.primary.main,
                                color: palette.background.alt,
                                "&:hover": { color: palette.primary.main },
                            }}
                        >
                            {isLogin ? "Login" : "Register"}
                        </Button>
                        <Typography
                            onClick={() => {
                                setPageType(isLogin ? "register" : "login");
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                color: palette.primary.main,
                                "&:hover": { 
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                            }}
                        >
                            {isLogin ? "Don't have an account? Register here" : "Already have an account? Login here"}
                        </Typography>
                    </Box>

                    {/* Buttons */}

                </form>
            )}
        </Formik>
    )
};

export default Form;