import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import { useNavigate } from "react-router-dom";
import { setLogout } from "state";
import PostWidget from "./PostWidget";
import UserWidget from "./UserWidget";
import NewPostWidget from "./NewPostWidget";


const PostsWidget = ({userId, avatarPath, isProfile = false}) => {
    const dispatch = useDispatch();
    const posts = useSelector((state) => state.posts);
    const token = useSelector((state) => state.token);
    const navigate = useNavigate();

    const getPosts = async () => {
        const response = await fetch("https://voices-backend.onrender.com/posts", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`}
            });
        if(!response.ok){
            if(response.status === 401){
                dispatch(setLogout())
                navigate("/");
            }
        }
        const data = await response.json();
        dispatch(setPosts({posts: data}));
    }

    const getUserPosts = async() => {
        const response = await fetch(`https://voices-backend.onrender.com/posts/${userId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`}
            });
        if(!response.ok){
            if(response.status === 401){
                //Unauthorized, log out
            }
        }
        const data = await response.json();
        dispatch(setPosts({posts: data}));
    }

    useEffect(() => {
        if(isProfile){
            getUserPosts();
        } else {
            getPosts();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        {isProfile ? <UserWidget userId={userId} avatarPath={avatarPath}/> : <NewPostWidget avatarPath={avatarPath} />}
            {Array.from(posts).reverse().map(
                ({
                    _id, 
                    userId,
                    firstName,
                    lastName,
                    description,
                    imagePath,
                    userPicturePath,
                    likes,
                    comments
                }) =>(
                    <PostWidget
                        key={_id}
                        postId = {_id}
                        postUserId = {userId}
                        name = {`${firstName} ${lastName}`}
                        description = {description}
                        imagePath = {imagePath}
                        avatarPath = {userPicturePath}
                        likes = {likes}
                        comments = {comments}
                    />
                )
                
            )}
        </>
    )


}

export default PostsWidget;