import { PersonAddOutlined, PersonRemoveOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {setFollowing } from 'state';
import FlexBetween from './FlexBetween';
import UserAvatar from './UserAvatar';
import { useEffect } from 'react';

const ProfileHeader = ({userId, name, avatarPath}) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { _id } = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    let following = useSelector((state) => state.user.following);
    const user = useSelector((state) => state.user);

    const { palette } = useTheme();
    const primaryLight = palette.primary.light;
    const primaryDark = palette.primary.dark;
    const main = palette.neutral.main;
    const medium = palette.neutral.medium;
    const isFollowing = following.find((following) => following === userId);

    const isUser = _id === userId;


    const patchFollowing = async () => {
        const response = await fetch(`https://voices-backend.onrender.com/users/${_id}/${userId}`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        });

        const data = await response.json();
        console.log("patched: ", data)
        //Extract _Id out of data and save it in the "following" array
        following = data.map((user) => user._id);

        dispatch(setFollowing({following: following}));

    };

    return(
        <FlexBetween>
            <FlexBetween gap="1rem">
                <UserAvatar image={avatarPath} size="55px"/>
                <Box onClick={() => {
                    navigate(`/profile/${userId}`);
                    navigate(0);
                }}>
                    <Typography color={main} variant="h5" fontWeight="500" sx={{"&:hover": { color: palette.primary.light, cursor: "pointer"}}}>
                        {name}
                    </Typography>
                </Box>
            </FlexBetween>
            {!isUser && (
            <IconButton
                onClick={() => patchFollowing()}
                sx={{backgroundColor: primaryLight, p: "0.6rem"}}
            >
                {isFollowing ? (
                    <PersonRemoveOutlined sx={{ color: medium }} />
                ) : (
                    <PersonAddOutlined sx={{ color: primaryDark }}/>
                )}
                
            </IconButton>
            )}
        </FlexBetween>
    )

    
};

export default ProfileHeader;
