import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdWidget from "scenes/widgets/AdWidget";


const HomePage = () => {
    const isNonMobileScreen = useMediaQuery('(min-width:1000px)');
    const { _id, avatarPath } = useSelector((state) => state.user);

    return (
    <Box>
        <Navbar />
        <Box width="100%" padding="2rem 6%" display={isNonMobileScreen ? "flex" : "block"} gap="0.5rem" justifyContent="space-between">
            <Box flexBasis={isNonMobileScreen ? "26%" : undefined}>
                <UserWidget userId={_id} avatarPath={avatarPath} />
            </Box>
            <Box flexBasis={isNonMobileScreen ? "42%" : undefined} mt={isNonMobileScreen ? undefined : "2rem"}>
                <PostsWidget userID={_id} avatarPath={avatarPath}/>
            </Box>
            {isNonMobileScreen && (<Box flexBasis="26%">
                <AdWidget/>
            </Box>)}
        </Box>
    </Box>)
}

export default HomePage;