import { Box, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import UserAvatar from "./UserAvatar";

const Comment = ({ comment }) => {

    return(
        <FlexBetween>
            <FlexBetween gap="1.3rem" p="0.3rem">
                <UserAvatar image={comment.avatarPath} size={"40px"} />
                <Box>   
                    {comment.firstName} {comment.lastName}
                    <Typography>
                        {comment.content}
                    </Typography>
                </Box>
                
            </FlexBetween>
            
        </FlexBetween>
    );

};

export default Comment;